import firebase from "firebase"

const firebaseConfig = {
    apiKey: "AIzaSyDMSeIoGsfnb1ysNWGMLcPV_678ATxJOiM",
    authDomain: "bigtournamentgolf-9d2bb.firebaseapp.com",
    projectId: "bigtournamentgolf-9d2bb",
    storageBucket: "bigtournamentgolf-9d2bb.appspot.com",
    messagingSenderId: "717874881223",
    appId: "1:717874881223:web:0d65794ccac7da4a6094e2",
    measurementId: "G-GB0JH65G47"
};

// Initialize Firebase
var fb = firebase.initializeApp(firebaseConfig); 

export default fb;
